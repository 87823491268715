var scrolltimeout1 = null;
var scrolltimeout2 = null;

$(function(){
    initScrollTo();
    checkViewport();
    initScroll();
    initContact();
    initMaterialDesign();
    initUpcount();
    initToggleBox();
});

function initScroll(){
    $(window).on('resize scroll', function() {
        window.clearTimeout(scrolltimeout1); 
        window.clearTimeout(scrolltimeout2); 
        scrolltimeout1 = window.setTimeout(checkViewport, 10);
        scrolltimeout2 = window.setTimeout(initUpcount, 10);
    });
    $('.text-container').each(function(){
        $(this).height($(this).height());
        $(this).css('overflow','hidden');
    });
}
function checkViewport(){
    $('.check-viewport').each(function(){
        if($(this).isInViewport()) {
            var that = $(this);
            window.setTimeout(function(){
                $(that).addClass('in-viewport');
            },10);
        } else {
            $(this).removeClass('in-viewport');
        }
    });
}

function initScrollTo(){
    $('nav a, .jumpto').each(function(){
        if(!$(this).hasClass('has-action')){
            $(this).on('click', function(){
                var target_id = $(this).attr('data-scrollto');
                var target = $('#scrollto-' + target_id);
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(target).offset().top
                }, 700);
            });
            $(this).addClass('has-action');
        }
    });
}

function initContact(){
    $('.add-submit').each(function(){
        if(!$(this).hasClass('has-submit')){
            $(this).on('click', function(){
                var formdata = $('#contactform').serialize();
                $('#messagebox').html('<div class=\"loader\"><img src=\"/pub/img/loading.gif\"></div>');
                $.ajax({
                    type: "POST",
                    url: '/contact',
                    data: formdata,
                    success: function(content) {
                        if(content == 'SEND TRUE'){
                            $('#messagebox').html('<div class="success">Ihre Anfrage wurde versendet, vielen Dank! Wir werden so rasch wie möglich mit Ihnen Kontakt aufnehmen.</div>');
                            $('.form .dvgui-form-group').hide('fast');
                        } else {
                            $('#messagebox').html('<div class="error">Es muss der Name plus entweder die Telefonnummer oder die E-Mail-Adresse angegeben werden, damit wir mit Ihnen Kontakt aufnehmen können.</div>');
                        }
                    }
                });
            });
            $(this).addClass('has-submit');
        }
    });
}

function initUpcount(){
    $('.upcount-container').each(function(){
        if($(this).isInViewport()) {
            if(!$(this).hasClass('has-upcount')){
                $(this).find('.upcount').each(function(){
                    var that = $(this);
                    window.setTimeout(function(){
                        var num_cont = $(that).find('span');
                        var num = parseInt(num_cont.text());
                        num_cont.text(0);
                        upcountNext(num,num_cont);
                    },10);
                });
                $(this).addClass('has-upcount')
            } 
        } else {
            $('.upcount-container').removeClass('has-upcount')
        }
    });
}

function upcountNext(num,num_cont){
    var upcount_steps = 20;
    var part = num/upcount_steps;
    console.log(part);
    var temp_num =  parseInt(num_cont.text());
    if(num > temp_num){
        window.setTimeout(function(){
            var new_num = Math.ceil(temp_num+part);
            num_cont.text(new_num);
            upcountNext(num,num_cont);
        },50);
    }
}

function initMaterialDesign() {

    var handleInput = function(el) {
        if (el.val() != "") {
            el.addClass('edited');
            if(el.hasClass('trumbowyg-textarea')){
                el.parent().addClass('edited');
            }
        } else {
            el.removeClass('edited');
            if(el.hasClass('trumbowyg-textarea')){
                el.parent().removeClass('edited');
            }
        }
    } 
    var handleErrors = function(el) {
        if (el.hasClass('dvForm_missed')) {
            el.parent('.form-group').addClass('has-error');
        } else if (el.find('input').hasClass('dvForm_missed')){
            el.parent('.form-group').addClass('has-error');
        }
    } 

    $('body').on('keydown', '.dvgui-text', function(e) { 
        handleInput($(this));
    });
    $('body').on('blur', '.dvgui-text, .dvgui-select', function(e) { 
        handleInput($(this));
    });
    $('body').on('select', '.dvgui-select', function(e) { 
        handleInput($(this));
    });
    $('.dvgui-text, .dvgui-select').each(function(){
        handleInput($(this));
    });
    $('.dvgui-radio label').on('click').each(function(){
        handleInput($(this));
    });
    /// init for errors
    $('.dvgui-text, .dvgui-select, .dvgui-checkbox, .dvgui-radio').each(function(){
        handleErrors($(this))
    });

    $('body').on('click', '.dvgui-checkbox > label, .dvgui-radio > label', function() {
        var the = $(this);
        var el = $(this).children('span:first-child');
        el.addClass('inc');
        var newone = el.clone(true);  
        el.before(newone);  
        $("." + el.attr("class") + ":last", the).remove();
    }); 
}

function showhideStatusform() {
    var container = $('.status-form')
    if(container.hasClass('hide')){
        container.removeClass('hide').addClass('show');
    } else {
        container.removeClass('show').addClass('hide');        
    }
    initStatus();
}
function initStatus(){
    $('.add-submit-status').each(function(){
        if(!$(this).hasClass('has-submit-status')){
            $(this).on('click', function(){
                var formdata = $('#statusform').serialize();
                $('#messagebox').html('<div class=\"loader\"><img src=\"/pub/img/loading.gif\"></div>');
                $.ajax({
                    type: "POST",
                    url: '/status/alert',
                    data: formdata,
                    success: function(content) {
                        if(content == 'SEND TRUE'){
                            $('#messagebox').html('<div class="success">Ihre Anfrage wurde versendet, vielen Dank! Wir werden so rasch wie möglich mit Ihnen Kontakt aufnehmen.</div>');
                            $('#statusform .dvgui-form-group').hide('fast');
                        } else {
                            $('#messagebox').html('<div class="error">Es muss der Name plus entweder die Telefonnummer oder die E-Mail-Adresse angegeben werden, damit wir mit Ihnen Kontakt aufnehmen können.</div>');
                        }
                    }
                });
            });
            $(this).addClass('has-submit');
        }
    });
}

function initToggleBox() {
    $('.toggle_container').each(function() {
        var toggle_header = $(this).children('.toggle_header');
        var svg = toggle_header.children('svg');
        if (toggle_header.length == 1 && !$(this).hasClass('has-togglebox')) {
            $(this).addClass('has-togglebox');
            var container = $(this);
            toggle_header.click(function() {
                var contentbox = container.find('.toggle_content');
                if (container.hasClass('opened')) {
                    container.removeClass('opened');
                    contentbox.slideUp();
                    svg.css('rotate','90deg');
                } else {
                    container.addClass('opened');
                    contentbox.slideDown();
                    svg.css('rotate','270deg');
                }
            });
        }
    });
}