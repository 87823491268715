var play = true;
var loop = false;
var clip_timeout = false;
$(function(){
    initClips();
});

function initClips(){
    $('.play').on('click', function(){
        //console.log('PLAY');
        play = true;
        var last = $('.clip-step').last();
        if(last.hasClass('current')){
            last.removeClass('current');
            $('.clip-step').first().addClass('current');
        }
        showNextStep();
    });
    $('.pause').on('click', function(){
        play = false;
    });
    $('.next').on('click', function(){
        showNextStep();
    });
    $('.prev').on('click', function(){
        showPrevStep();
    });
    $('.loop').on('click', function(){
        if(loop){
            loop = false;
            $(this).removeClass('on');
        } else {
            loop = true
            $(this).addClass('on');
        }
    });
    showNextStep();
}
function showNextStep(){
    window.clearTimeout(clip_timeout);
    var curr = $('.clip-step.current');
    if(curr) {
        var next = curr.next('.clip-step')
    } 
    if(next.length > 0){
        var time = next.attr('data-time');
        curr.removeClass('current');
        next.addClass('current')
        //console.log('SHOW Next Step: ' + time + ' >> ' + play);
        if(time && play){
            clip_timeout = window.setTimeout(showNextStep,time);
        } else {
            window.clearTimeout(clip_timeout); // dont play
        }
    } else {
        if(loop){
            $('.clip-step').first().addClass('current');
            console.log('LOOOP');
            clip_timeout = window.setTimeout(showNextStep,5000);
        }
    }
}
function showPrevStep(){
    window.clearTimeout(clip_timeout);
    var curr = $('.clip-step.current');
    if(curr) {
        var prev = curr.prev('.clip-step')
    } 
    if(prev.length > 0){
        var time = prev.attr('data-time');
        curr.removeClass('current');
        prev.addClass('current')
        //console.log('SHOW Prev Step: ' + time + ' >> ' + play);
        if(time && play){
            clip_timeout = window.setTimeout(showNextStep,time);
        } else {
            window.clearTimeout(clip_timeout); // dont play
        }
    }
}